import { createId } from '@paralleldrive/cuid2';
import * as React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../reducers';
import { capitalize } from '../../../utils/strings';
import { ExpandableSection } from '../ExpandableSection';
import './PreviousQueries.css';

import Modal from '../../Modal/Modal';
import { DefaultButton } from '../../ActionButton/ActionButton';
import { fetchQueryRequest } from '../../../app/Query/actions';

// Possible development for query search details
// import { ActionButton } from '../../ActionButton/ActionButton';
import { useHistory } from 'react-router';
import { store } from '../../../store';

interface IProps {
  query?: any;
}

interface IQueryProps {
  query: any;
  modal?: any;
}

const ShareModal: React.FC<IQueryProps> = ({ query }) => (
  <Modal>
    <p>Share this query by copying the link below and sending it to your contact.</p>

    <p>{query.identifier}</p>
  </Modal>
);

const PreviousQueries: React.FC<IProps> = ({ query }) => {
  // Possible development for query search details
  const history = useHistory();

  const { previousQueries } = query;
  const queriesByScope = previousQueries.reduce((acc: any[], QueryData: any) => {
    const { scope } = QueryData;
    if (!acc[scope]) {
      acc[scope] = [];
    }
    acc[scope].push(QueryData);
    return acc;
  }, {});

  const formatDateTime = (dateTime: string) => {
    const date = new Date(dateTime);
    return `${date.toLocaleDateString()} at ${date.toLocaleTimeString()}`;
  };

  const handleRedoQuery = (identifier: string) => {
    store.dispatch(fetchQueryRequest(identifier));
    history.push('/');
  };

  return (
    <ExpandableSection title="Previous queries" count={previousQueries.length}>
      <div className="previous-queries">
        <ShareModal query={query.selectedQuery} />

        {[
          'FUNDING',
          {
            /*'NATIONAL'*/
            // Uncomment when National funding gets enabled
          },
          'PARTNERS',
          'PROJECTS',
        ].map((scope: string) => {
          const queries = queriesByScope[scope];
          if (!queries) {
            return null;
          }

          return (
            <ExpandableSection key={scope} title={capitalize(scope.toLowerCase())} count={queries.length}>
              {queries.map((getEachQuery: any) => (
                <div className="previous-queries_contianer" key={createId()}>
                  <span className="queries"><p className="previous-queries__query">"<b>{getEachQuery.query}</b>", searched on {formatDateTime(getEachQuery.updatedAt)}</p></span>
                  <DefaultButton
                    handleClick={() => handleRedoQuery(getEachQuery.identifier)}
                    text="Search again"
                  />
                </div>
              ))}
            </ExpandableSection>
          );
        })}
      </div>
    </ExpandableSection>
  );
};

const mapStateToProps = ({ modal, query }: IState) => {
  return { modal, query };
};

export default connect(mapStateToProps)(PreviousQueries);
