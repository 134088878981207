import * as React from 'react';
import { ActionButton } from '../ActionButton/ActionButton';
import './UploadFile.css';
import CloseIcon from '../../assets/cancel.svg';
import { store } from '../../store';
import { setUploadedFile } from '../../app/Fundings/actions';

interface UploadFileProps {
  acceptTypes: string;
}

const UploadFile: React.FC<UploadFileProps> = ({ acceptTypes }) => {
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);

  const handleFileChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const fileInput = event.target;
      const file = fileInput?.files?.[0];
      if (file) {
        event.target.value = '';
        store.dispatch(setUploadedFile(file));
        setSelectedFile(file);
      } else {
        store.dispatch(setUploadedFile(null));
        setSelectedFile(null);
      }
    },
    [selectedFile, setSelectedFile],
  );

  const handleCloseIcon = () => {
    setSelectedFile(null);
    store.dispatch(setUploadedFile(null));
  };

  return (
    <div className="upload-file-container">
      <input
        type="file"
        accept={acceptTypes}
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="file-upload"
      />
      <div className="file-selection-container">
        <div className='file-container'>
            <ActionButton text='Choose file' handleClick={() => document.getElementById('file-upload')?.click()} />
            <span className='selected-file-container'>
                {!selectedFile ? 'No file selected' : selectedFile?.name}
            </span>
        </div>
        {selectedFile && <img src={CloseIcon} onClick={handleCloseIcon} />}
      </div>
    </div>
  );
};

export default UploadFile;
