import * as React from 'react';
import { Route } from 'react-router-dom';

import App from './components/App/App';
import { Login } from './components/Login/Login';
import ProfilePage from './components/ProfilePage';
import { QueryPage } from './components/QueryPage';
import { SearchPage } from './components/Search';

import * as auth from './app/Auth/Auth';
import Callback from './components/Callback/Callback';

import HealthCheckPage from './components/Health/HealthCheckPage';
import LicenseAgreement from './components/LicenseAgreement/LicenseAgreement';
import { FundingsPage } from './components/Fundings/FundingsPage';
import { AISearchPage } from './components/AISearch';

const handleAuthentication = (nextState: any, _replace?: any) => {
  if (/access_token|id_token|error/.test(nextState.location.hash)) {
    auth.handleAuthentication();
  }
};

const Routes: React.FC = () => {
  return (
    <App>
      <Route exact={true} path="/" component={SearchPage} />

      <Route path="/profile" component={ProfilePage} />

      <Route exact={true} path="/search" component={SearchPage} />

      <Route path="/login" component={Login} />

      <Route path="/query/:identifier" component={QueryPage} />

      <Route
        path="/license-agreement"
        render={() => {
          return <LicenseAgreement continue="/search" />;
        }}
      />

      <Route path="/register" component={LicenseAgreement} />

      <Route path="/health" component={HealthCheckPage} />

      <Route
        path="/analytics"
        render={() => {
          const emailVerified = localStorage.getItem('isEmailVerified') === 'true';
          return emailVerified ? <FundingsPage /> : <SearchPage />;
        }}
      />

      <Route
        path="/callback"
        render={(props) => {
          handleAuthentication(props);
          return <Callback {...props} />;
        }}
      />

      <Route exact={true} path="/aisearch" component={AISearchPage} />
    </App>
  );
};

export default Routes;
