import { action } from 'typesafe-actions';
// import { SearchActionTypes } from '../Search/actions';

export const enum selectableTabs {
  FUNDING = 'FUNDING',
  NATIONAL = 'NATIONAL',
  PARTNERS = 'PARTNERS',
  PROJECTS = 'PROJECTS',
}

export interface IAISearchQueryData {
  query: string;
  limit?: number;
  statuses?: string[];
  exclude?: any[];
  be_offset?: number;
}

export interface IAISearchQuery {
  query: string;
  scope?: string;
}

export interface IAISearchQueryResults {
  data: IAISearchQuery[];
}

export interface IAISearchClosedTopicsQuery {
  topics: { identifier: string };
}

export interface IAISearchResult {
  call_title: string;
  title: string;
  similarity: string;
  type_of_action: string;
  deadline_dates: string[];
  call_identifier: string;
  topic_status: string;
  description: string;
  budget_numbers: string;
  trl_text: string;
  identifier: string;
  topic_id: string;
  publication_date: string;
  planned_opening_date: string;
  deadline1: string;
  budget_groups: object[];
  topic_url: string;
  be_offset: number;
}

export interface IAISearchResults {
  // data: IAISearchResult[];
  
    be_offset: number,
    results: IAISearchResult;
  
}

export const enum AISearchActionTypes {
  SEARCH_REQUEST = '@@ai-search/REQUEST',
  SEARCH_SUCCESS = '@@ai-search/SUCCESS',
  SEARCH_MORE_REQUEST = '@@ai-search/MORE_REQUEST',
  SEARCH_MORE_SUCCESS = '@@ai-search/MORE_SUCCESS',
  SEARCH_CLOSED_TOPICS_REQUEST = '@@ai-search/CLOSED_TOPICS_REQUEST',
  SEARCH_CLOSED_TOPICS_SUCCESS = '@@ai-search/CLOSED_TOPICS_SUCCESS',
  SEARCH_ERROR = '@@ai-search/ERROR',
  SET_QUERY = '@@ai-search/SET_QUERY',
  SET_FORM_VALID = '@@ai-search/SET_FORM_VALID',
  SELECT_TAB = '@@ai-search/SELECT_TAB',
  SET_ORDER_CONDITION = '@@ai-search/SET_ORDER_CONDITION',
  ADD_FILTER_CONDITION = '@@ai-search/ADD_FILTER_CONDITION',
  REMOVE_FILTER_CONDITION = '@@ai-search/REMOVE_FILTER_CONDITION',
  CLEAR_FILTER = '@@ai-search/CLEAR_FILTER',
  SELECT_ITEM = '@@ai-search/SELECT_ITEM',
  UNSELECT_ITEM = '@@ai-search/UNSELECT_ITEM',
  FETCH_TOPIC_SUCCESS = '@@ai-search/FETCH_TOPIC_SUCCESS',
  CLEAR_SELECTED_TOPIC = '@@ai-search/CLEAR_SELECTED_TOPIC',
  FETCH_TOPIC_INTERESTED_PARTNERS_REQUEST = '@@ai-search/FETCH_TOPIC_INTERESTED_PARTNERS_REQUEST',
  FETCH_TOPIC_INTERESTED_PARTNERS_SUCCESS = '@@ai-search/FETCH_TOPIC_INTERESTED_PARTNERS_SUCCESS',
  FETCH_TOPIC_DESCRIPTION_REQUEST = '@@ai-search/FETCH_TOPIC_DESCRIPTION_REQUEST',
  FETCH_TOPIC_DESCRIPTION_SUCCESS = '@@ai-search/FETCH_TOPIC_DESCRIPTION_SUCCESS',
  CLEAR_TOPIC_DESCRIPTION = '@@ai-search/CLEAR_TOPIC_DESCRIPTION',
}

export const aiSearchRequest = (data: IAISearchQueryData) => action(AISearchActionTypes.SEARCH_REQUEST, data);

export const aiSearchResponse = (data: IAISearchResults) => action(AISearchActionTypes.SEARCH_SUCCESS, data);

export const aiSearchMoreRequest = (data: IAISearchQueryData) =>
  action(AISearchActionTypes.SEARCH_MORE_REQUEST, data);

export const aiSearchMoreResponse = (data: IAISearchResults) =>
  action(AISearchActionTypes.SEARCH_MORE_SUCCESS, data);

export const aiSearchClosedTopicsRequest = (data: IAISearchClosedTopicsQuery) =>
  action(AISearchActionTypes.SEARCH_CLOSED_TOPICS_REQUEST, data);

export const aiSearchClosedTopicsResponse = (data: IAISearchResults) =>
  action(AISearchActionTypes.SEARCH_CLOSED_TOPICS_SUCCESS, data);

export const aiSearchError = (data: {}) => action(AISearchActionTypes.SEARCH_ERROR, data);

export const aiSetFormValid = () => action(AISearchActionTypes.SET_FORM_VALID);

export const aiSetQuery = (data: IAISearchQuery) => action(AISearchActionTypes.SET_QUERY, data.query);

export const aiSelectTab = (data: string) => action(AISearchActionTypes.SELECT_TAB, data);

export const aiSetOrderCondition = (condition: string) =>
  action(AISearchActionTypes.SET_ORDER_CONDITION, condition);

export const aiAddFilterCondition = (key: string, value: string) =>
  action(AISearchActionTypes.ADD_FILTER_CONDITION, { key, value });

export const aiRemoveFilterCondition = (key: string, value: string) =>
  action(AISearchActionTypes.REMOVE_FILTER_CONDITION, { key, value });

export const aiClearFilter = () => action(AISearchActionTypes.CLEAR_FILTER);

export const aiSelectItem = (item: any) => action(AISearchActionTypes.SELECT_ITEM, item);

export const aiUnSelectItem = (item: any) => action(AISearchActionTypes.UNSELECT_ITEM, item);

export const aiFetchTopicSuccess = (item: IAISearchResult) => action(AISearchActionTypes.FETCH_TOPIC_SUCCESS, item);

export const aiClearSelectedTopic = () => action(AISearchActionTypes.CLEAR_SELECTED_TOPIC);

export const aiFetchTopicInterestedPartnersSuccess = (topicInterestedPartner: any, topicId: any) =>
  action(AISearchActionTypes.FETCH_TOPIC_INTERESTED_PARTNERS_SUCCESS, {
    topicInterestedPartner,
    topicId,
  });

export const aiFetchTopicDescription = (data: any) =>
  action(AISearchActionTypes.FETCH_TOPIC_DESCRIPTION_REQUEST, data);

export const aiFetchTopicDescriptionSuccess = (topicDescription: any) =>
  action(AISearchActionTypes.FETCH_TOPIC_DESCRIPTION_SUCCESS, topicDescription);

export const aiClearTopicDescription = () => action(AISearchActionTypes.CLEAR_TOPIC_DESCRIPTION);