import * as React from 'react';

import { ISearchState } from '../../../../../app/Search/reducer';
import { convertToCurrency } from '../../../../../utils/numberConverter';
import { Accordion } from '../../../../Accordion';

interface ISearchResultMainProps {
  searchResults: ISearchState;
  topic: {
    id: any;
    metadata: {
      title: string;
      identifier: string;
      description: string;
      topic_url: string;
      call_url: string;
      call_programme: string;
      call_identifier: string;
      call_title: string;
      call_status: string;
      topic_status: string;
      call_programme_description: string;
      publication_date: string;
      planned_opening_date: string;
      deadline1: string;
      tags: string[];
      keywords: string[];
      summary_terms: string[];
      budgets_from_call: any[];
      budget_groups: any[];
    };
  };
}

export const SearchResultMain = ({ searchResults, topic }: ISearchResultMainProps) => {
  const closedTopic =
    searchResults.isClosedTopicsFetching || !topic.metadata
      ? undefined
      : searchResults.closedTopics.find(
          (searchResult) =>
            topic.metadata.identifier === searchResult.identifier &&
            searchResult.additional_data &&
            searchResult.additional_data.h2020_projects.length > 0,
        );

  return (
    <div className="topic__description">
      {topic && topic.metadata && topic.metadata.call_status !== 'Closed' ? (
        <div
          dangerouslySetInnerHTML={{
            __html: searchResults.topicDescription[topic.id],
          }}
        />
      ) : null}

      {topic && topic.metadata && topic.metadata.topic_status === 'Closed' ? (
        <Accordion showTitle="Show call description" hideTitle="Hide call description">
          <div
            dangerouslySetInnerHTML={{
              __html: searchResults.topicDescription[topic.id],
            }}
          />
          <hr />
        </Accordion>
      ) : null}

      {closedTopic ? (
        <div className="topic__additional-data">
          <h2>Funded projects & organisations</h2>
          {closedTopic.additional_data.h2020_projects.map((project: any) => {
            const organizationsByActivityType = groupByKey(project.h2020_organizations, 'activityType');

            return (
              <div key={project.id}>
                <h3>{project.title}</h3>
                <ul>
                  <li>
                    <a href={`https://cordis.europa.eu/project/rcn/${project.rcn}_en.html`} target="_blank">
                      Project website
                    </a>
                  </li>

                  {project.h2020_organizations && project.h2020_organizations.length > 0 && (
                    <li>
                      Organisations
                      <ul>
                        {Object.entries(organizationsByActivityType).map(
                          ([activityType, organizations]: [string, any]) => {
                            return (
                              <li key={activityType}>
                                {activityType}
                                <ul>
                                  {organizations.map((organization: any) => {
                                    return (
                                      <li key={organization.id}>
                                        <strong>{organization.name}</strong> (Public funding:{' '}
                                        {convertToCurrency(organization.ecContribution)})
                                      </li>
                                    );
                                  })}
                                </ul>
                              </li>
                            );
                          },
                        )}
                      </ul>
                    </li>
                  )}
                </ul>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

const groupByKey = (array: any[], key: string) => {
  return array.reduce((acc: any[], item: any) => {
    const keyValue = item[key];
    if (!acc[keyValue]) {
      acc[keyValue] = [];
    }
    acc[keyValue].push(item);
    return acc;
  }, {});
};
