import * as React from 'react';
import { store } from '../../../store';
import { CheckboxGroup } from '../../Search/SearchResults/Filter/CheckboxGroup';
import {
  searchRequestFundings,
  exportRequest,
  setIsExporting,
  setClearExporting,
} from '../../../app/Fundings/actions';
import { useSelector } from 'react-redux';
import { Spinner } from '@fluentui/react/lib/Spinner';
import { exportToExcel } from '../../../utils/excelExport';
import IconArrowRigh from '../../../assets/icon--arrow--right.svg';

const statuses = [
  { key: 'topic_status', value: '31094504', label: 'Draft'},
  { key: 'topic_status', value: '31094501', label: 'Forthcoming' },
  { key: 'topic_status', value: '31094502', label: 'Open' },
  { key: 'topic_status', value: '31094503', label: 'Closed' },
];

const excludedFields = [
  'reference',
  'workProgrammepart',
  'additionalInfos',
  'actions',
  'topicConditions',
  'type',
  'programmePeriod',
];

const renamedFields = {
  identifier: 'topicIdentifier',
  summary: 'topicName',
  descriptionByte: 'description',
  startDate: 'openingDate',
  budgetOverview: 'budget',
};

const fieldOrder = [
  'topicIdentifier',
  'topicName',
  'typesOfAction',
  'callIdentifer',
  'callTitle',
  'description',
  'deadlineDate',
  'deadlineModel',
  'budget',
  'keywords',
  'tags',
  'openingDate',
  'url',
];

const Filter: React.FC = () => {
  const [frameworkProgramme, setFrameworkProgramme] = React.useState<any[]>([]);
  const [callProgramme, setCallProgramme] = React.useState<string[]>(['43108390']);
  const [callStatus, setCallStatus] = React.useState<string[]>(['31094501', '31094502', '31094504']);
  const [label, setLabel] = React.useState<string>('');
  
  // Use useSelector inside the component to always get the latest state
  const exportData = useSelector((state: any) => state.funding.exportResults);
  const framewokrProgrammes = useSelector((state: any) => state.funding.frameworkProgrammes);
  const limit: number = 10;
  const offset: number = 0;
  const isExporting = useSelector((state: any) => state.funding.isExporting);
  const isloading = useSelector((state: any) => state.funding.loading);
  const EU_REDIRECT_URL: string = process.env.REACT_APP_EU_REDIRECT_URL || 'https://ec.europa.eu/info/funding-tenders/opportunities/portal/screen/opportunities/topic-details/'
  const isTrialUser = localStorage.getItem('isTrialUser') === "true";
  const fundingResults = useSelector((state: any) => state.funding.results);

  // Use useMemo to memoize the transformation of framework programmes
  const transformedFrameworkProgramme = React.useMemo(() => {
    return framewokrProgrammes.map((item: any) => ({
      key: 'framework_programme',
      value: item.programmeId,
      label: item.programmeName,
    })).sort((a: any, b: any) => a.label.localeCompare(b.label));
  }, [framewokrProgrammes]);

  // Update framework programme state whenever transformed data changes
  React.useEffect(() => {
    setFrameworkProgramme(transformedFrameworkProgramme);
  }, [transformedFrameworkProgramme]);

  const handleCallProgrammeChange = (value: any) => {
    setCallProgramme((prev) => {
      const updatedProgramme = prev.includes(value) ? prev.filter((status) => status !== value) : [value];
      applyFilters(updatedProgramme, callStatus);
      return updatedProgramme;
    });
  };

  const handleCallStatusChange = (value: any) => {
    setCallStatus((prev) => {
      const updatedStatus = prev.includes(value)
        ? prev.filter((status) => status !== value)
        : [...prev, value];
      applyFilters(callProgramme, updatedStatus);
      return updatedStatus;
    });
  };

  const handleExport = (updatedProgramme: string[], updatedStatus: string[]) => {
    const getLabels = frameworkProgramme
      .filter((programme) => updatedProgramme.includes(programme.value))
      .map((matchedProgramme) => matchedProgramme.label);
    setLabel(getLabels[0]);
    store.dispatch(setIsExporting(true));
    store.dispatch(
      exportRequest({
        frameworkProgramme: JSON.stringify(updatedProgramme),
        status: JSON.stringify(updatedStatus),
      }),
    );
  };

  const applyFilters = (updatedProgramme: string[], updatedStatus: string[]) => {
    store.dispatch(
      searchRequestFundings({
        frameworkProgramme: JSON.stringify(updatedProgramme),
        status: JSON.stringify(updatedStatus),
        limit: limit,
        offset: offset,
      }),
    );
  };

  React.useEffect(() => {
    applyFilters(callProgramme, callStatus);
  }, []);

  React.useEffect(() => {
    if (exportData && exportData.length > 0) {
      const processData = exportData.map((item: any) =>
        Object.entries(item).reduce((acc: any, [key, value]) => {
          acc[key] = Array.isArray(value) ? JSON.stringify(value) : value;
          return acc;
        }, {}),
      );
      exportToExcel(processData, label, excludedFields, renamedFields, fieldOrder, EU_REDIRECT_URL);
      store.dispatch(setClearExporting());
      store.dispatch(setIsExporting(false));
    }
  }, [exportData]);

  const filterableItems = [
    {
      value: 'call_programme_description',
      name: 'Call programme',
      items: frameworkProgramme,
      onChange: handleCallProgrammeChange,
      selectedItem: callProgramme,
    },
    {
      value: 'call_status',
      name: 'Call status',
      items: statuses,
      onChange: handleCallStatusChange,
      selectedItem: callStatus,
    },
  ];

  const clearFilters = () => {
    setCallProgramme([]);
    setCallStatus([]);
  };

  return (
    <div
      className="result-filter__container"
      style={isExporting || isloading ? { opacity: 0.75, pointerEvents: 'none' } : {}}
    >
      <div className="result-filter">
        <p className="filter-group-label">Filter by</p>
        {frameworkProgramme.length > 0 ? (
          filterableItems.map((filterable: any) => (
            <CheckboxGroup
              key={`${filterable.name}_${filterable.value}`}
              label={filterable.name}
              name={filterable.name}
              onChange={filterable.onChange}
              options={filterable.items}
              value={filterable.selectedItem}
              hasShowMore={filterable.name === 'Call status' ? false : true}
            />
          ))
        ) : (
          <Spinner />
        )}
        {callProgramme.length > 0 || callStatus.length > 0 ? (
          <div className="result-filter__filter-item">
            <button className="result-filter__clear-button" onClick={clearFilters}>
              Reset filters
            </button>
          </div>
        ) : null}
        {(callProgramme.length > 0 || callStatus.length > 0) && !isTrialUser && fundingResults.length > 0 ? (
          <div className="result-filter__filter-item">
            <p className="filter-group-label" style={{ marginTop: '30px' }}>
              Export all
            </p>

            {!isExporting ? (
              <div className="export-all">
                <img src={IconArrowRigh} alt="next" className="export-icon" />
                <li onClick={() => handleExport(callProgramme, callStatus)} className="dropdown__item">
                  Export as excel (.xlsx)
                </li>
              </div>
            ) : (
              <Spinner />
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Filter;
