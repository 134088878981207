import { Icon } from '@fluentui/react/lib/Icon';
import { createId } from '@paralleldrive/cuid2';
import * as moment from 'moment';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { getNextDeadline } from './SearchResult/Share';

import Budgets from './Budgets';

interface ITopicInformationProps {
  topicMetadata: any;
}

interface ITopicInformationState {
  budgets_collapsed: boolean;
  deadlines_collapsed: boolean;
}

const TopicInformation: React.FC<ITopicInformationProps> = ({ topicMetadata }) => {
  const [state, setState] = React.useState<ITopicInformationState>({
    budgets_collapsed: true,
    deadlines_collapsed: true,
  });

  const { deadlines_collapsed } = state;

  if (!topicMetadata) {
    return (
      <div>
        <ul className="topic-information">
          <li>
            <strong>Programme</strong>

            <p>
              <Skeleton height={12} />
            </p>
          </li>

          <li>
            <strong>Topic status</strong>

            <p>
              <Skeleton />
            </p>
          </li>

          <li>
            <strong>Call identifier</strong>

            <p>
              <Skeleton />
            </p>
          </li>

          <li>
            <strong>Call title</strong>

            <p>
              <Skeleton />
            </p>
          </li>

          <li>
            <strong>Publication date</strong>

            <p>
              <Skeleton />
            </p>
          </li>

          <li>
            <strong>Opening date</strong>

            <p>
              <Skeleton />
            </p>
          </li>
        </ul>
      </div>
    );
  }
  const nextDeadline =
    (topicMetadata.deadline_dates[0] !== 'N/A' && (topicMetadata.topic_status === 'Open' || topicMetadata.topic_status === 'Draft'))
      ? moment(getNextDeadline(topicMetadata.deadline_dates)).format('DD MMM YYYY')
      : topicMetadata.topic_status;

  // Fixing the call page feature: 
  // Generating the required call page url    
  const parts = topicMetadata.topic_url.split('/');
  const callIdentifier = parts[parts.length - 1].split('-').slice(0, -1).join('-').toUpperCase(); 
  const baseURL = 'https://ec.europa.eu/info/funding-tenders/opportunities/portal/screen/opportunities/topic-search';
  // Required call page url:
  const callPageUrl = `${baseURL}?tenders=false&programmePart=&callIdentifier=${callIdentifier}`;
  
  return (
    <div>
      <ul className="topic-information">
        <li>
          <strong>Programme</strong>
          {topicMetadata.call_programme_description} ({topicMetadata.call_programme})
        </li>

        <li>
          <strong>Topic status</strong>

          {topicMetadata.topic_status}
        </li>

        <li>
          <strong>Call identifier</strong>

          {topicMetadata.call_identifier}
        </li>

        <li>
          <strong>Call title</strong>

          {topicMetadata.call_title}
        </li>

        <li>
          <strong>Publication date</strong>
          {topicMetadata.publication_date === 'N/A'
            ? 'N/A'
            : moment(topicMetadata.publication_date).format('DD MMM YYYY')}
        </li>

        <li>
          <strong>Opening date</strong>
          {topicMetadata.planned_opening_date === 'N/A'
            ? 'N/A'
            : moment(topicMetadata.planned_opening_date).format('DD MMM YYYY')}
        </li>

        <li>
          <React.Fragment>
            <a
              className="topic-info-drop"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setState({
                  budgets_collapsed: state.budgets_collapsed,
                  deadlines_collapsed: !state.deadlines_collapsed,
                });
              }}
            >
              <strong>
                Next deadline: {nextDeadline}{' '}
                {deadlines_collapsed ? <Icon iconName="ChevronDown" /> : <Icon iconName="ChevronUp" />}
              </strong>
            </a>

            <ul>{deadlines_collapsed ? '' : <Deadlines deadlines={topicMetadata.deadline_dates} />}</ul>
          </React.Fragment>
        </li>

        <li>
          <Budgets budget_groups={topicMetadata.budget_groups} />
        </li>

        {topicMetadata.topic_url || topicMetadata.call_url ? (
          <li>
            <strong>External links:</strong>
            <div className="external-links">
              {topicMetadata.topic_url ? (
                <a className="action-button" target="_blank" href={topicMetadata.topic_url}>
                  Topic page
                </a>
              ) : null}

              {topicMetadata.call_url ? (
                <a className="action-button" target="_blank" href={callPageUrl}>
                  Call page
                </a>
              ) : null}
            </div>
          </li>
        ) : null}
      </ul>
    </div>
  );
};

export default TopicInformation;

interface IDeadlinesProps {
  deadlines: any[];
}

const Deadlines: React.FC<IDeadlinesProps> = ({ deadlines }) => {
  return (
    <React.Fragment>
      {deadlines.map((deadline: string) => {
        return <li key={createId()}>{formatDate(deadline)}</li>;
      })}
    </React.Fragment>
  );
};

const formatDate = (dateToFormat: string) => {
  if (dateToFormat === 'N/A') {
    return 'Ongoing';
  }
  return moment(dateToFormat).format('DD MMMM YYYY');
};
