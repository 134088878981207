import { IColumn } from '@fluentui/react/lib/DetailsList';
import { Spinner } from '@fluentui/react/lib/Spinner';
import { createId } from '@paralleldrive/cuid2';
import memoize from 'memoize-one';
import type { Moment } from 'moment';
import * as moment from 'moment';
import * as React from 'react';

import { connect } from 'react-redux';
import { isTrialUser } from '../../../app/Auth/Auth';
import { partnerSearchRequest, searchByCallDescription } from '../../../app/PartnerSearch/actions';
import { removeSavedDocumentRequest, saveDocumentRequest } from '../../../app/Saved/actions';
import {
  selectableTabs,
  // selectItem,
  selectTab,
  // unSelectItem,
} from '../../../app/Search/actions';
import { IState } from '../../../reducers';
import { store } from '../../../store';
import { containsValue } from '../../../utils/filter';
import { getTrlNumber } from '../../../utils/numberConverter';
import { ActionButton, DefaultButton } from '../../ActionButton/ActionButton';
import Checkbox from '../../CheckBox/ChechBox'
import { Share } from './SearchResult/SearchResult/Share';
import ResultLabel from './ResultLabel';
import SearchResult from './SearchResult/SearchResult';
import { SearchResultDetail } from './SearchResultDetail';
import './SearchResults.css';
import { aiSearchClosedTopicsRequest, aiSearchMoreRequest, aiSelectItem, aiUnSelectItem, IAISearchResult, } from '../../../app/AiSearch/actions';

interface ICallRowProps {
  index: number;
  call: IAISearchResult;
  selected: boolean;
  saved: boolean;
  selectCall: (call: any) => void;
}

const CallRow: React.FC<ICallRowProps> = ({ index, call, selected, saved, selectCall }) => {  
  const handleReadMore = () => {
    store.dispatch(aiSelectItem(call));
    selectCall(call);
    document.body.classList.add('preventModalScrolling');
  };

  const performPartnerSearch = () => {
    store.dispatch(selectTab(selectableTabs.PARTNERS));
    store.dispatch(searchByCallDescription(call.title.replace(/<(?:.|\n)*?>/gm, '')));
    store.dispatch(partnerSearchRequest(call.description.replace(/<(?:.|\n)*?>/gm, '')));
  };

  const toggleSelected = () => {
    if (!selected) {
      store.dispatch(aiSelectItem(call));
    } else {
      store.dispatch(aiUnSelectItem(call));
    }
  };

  const handleSave = () => {
    store.dispatch(
      saveDocumentRequest({
        scope: selectableTabs.FUNDING,
        objectId: call.topic_id,
        title: `${call.call_identifier} - ${call.title}`,
        deadline: call.deadline1 !== 'N/A' ? moment(call.deadline1).format('YYYY-MM-DD') : undefined,
      }),
    );
  };

  const handleUnsaved = () => {
    store.dispatch(removeSavedDocumentRequest(call.topic_id));
  };

  const getDeadlineDate = () => {
    if (getNextDeadline(call.deadline_dates) === 'N/A' && call.topic_status !== 'Closed' && call.topic_status !== 'Draft') {
      return 'Ongoing';
    }
    if (getNextDeadline(call.deadline_dates) === 'N/A' && call.topic_status === 'Closed' ) {
      return 'Closed';
    }
    if (getNextDeadline(call.deadline_dates) === 'N/A' && call.topic_status === 'Draft') {
      return 'Draft';
    }
    return moment(getNextDeadline(call.deadline_dates)).format('DD.MM.YYYY');
  };

  // const getDeadlineDate = () => {
  //   if (getNextDeadline(call.deadline_dates) !== 'N/A') {
  //     const deadlineDate = getNextDeadline(call.deadline_dates);
  //     return moment(deadlineDate).format('DD.MM.YYYY');
  //   } else {
  //     return 'Ongoing';
  //   }
  // };

  const budgetValue = getBudgetFromBudgetGroups(call.budget_groups as IBudgetGroup[]);

  var resultTitle;
  if (call.topic_status === "Draft") {
    resultTitle = (
      <span style={{ color: 'red', fontWeight: 'bold', paddingRight: "2px" }}>
        [DRAFT]
      </span>
    );
    if (call.call_identifier) {
      resultTitle = (
        <>
          {resultTitle}{call.call_identifier}
        </>
      );
    }
  } else {
    resultTitle = call.call_identifier || '';
  }

  return (
    <div className="search-results--row">
      {!isTrialUser() ? (
        <div className="search-results--first-column">
          <div className="search-results--result-name">
          {resultTitle}
          {' '}
          {call.title}
          </div>

          <div className="search-results--result-description">{call.description.replace(' ...', '...')}</div>

          <div className="search-results--buttons">
            <div className="search-results--buttons__default">

              <DefaultButton text="Read more" handleClick={handleReadMore} />

              {/* <Checkbox
                label="Add to Export"
                checked={selected}
                onChange={toggleSelected}
              /> */}

            </div>

            <div className="search-results--buttons__action">

              {/* <ActionButton text="Perform partner search" handleClick={performPartnerSearch} />
              {saved ? (
                <ActionButton text="Unsave" handleClick={handleUnsaved} selected={true} />
              ) : (
                <ActionButton text="Save" handleClick={handleSave} />
              )} */}
            </div>
          </div>
        </div>
      ) : (
        <div className="search-results--first-column">
          {isTrialUser() && index < 3 ? (
            <div>
              <div className="search-results--result-name">
                {call.call_identifier}
                {call.call_identifier ? ' - ' : ''}
                {call.title}
              </div>

              <div className="search-results--result-description">
                {call.description.replace(' ...', '...')}
              </div>

              <div className="search-results--buttons">
                <div className="search-results--buttons__default">
                  <DefaultButton text="Read more" handleClick={handleReadMore} />

                  <Checkbox
                    label="Add to Export"
                    checked={selected}
                    onChange={toggleSelected}
                  />
                </div>

                <div className="search-results--buttons__action">

                  <ActionButton text="Perform partner search" handleClick={performPartnerSearch} />

                  {saved ? (
                    <ActionButton text="Unsave" handleClick={handleUnsaved} selected={true} />
                  ) : (
                    <ActionButton text="Save" handleClick={handleSave} />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="search-results--subscribe">
              <div dangerouslySetInnerHTML={{ __html: call.description }} />
            </div>
          )}
        </div>
      )}

      <div className="search-results--second-column">
        <div className="search-results__details">
          <SearchResultDetail
            value={`${call.similarity}%`.replace('.', ',')}
            label="Match"
            highlight={true}
            type="MATCH"
            tooltip={{
              content: (
                <p>
                  Match %: This is the similarity score that the AI engine has given to each one of the search
                  results. It means that AI has read the whole text you provided as search query, and analysed
                  it word by word and then compared that with the texts associated with each funding call
                  topics in our entire database, and as a result it is recommending that the higher the % is,
                  there is a better match.
                </p>
              ),
              position: 'topLeft',
            }}
          />

          <SearchResultDetail
            value={getDeadlineDate()}
            label={'Deadline'}
            highlight={false}
            type="DEADLINE"
            tooltip={{
              content: <p>Next deadline for this topic.</p>,
              position: 'topLeft',
            }}
          />

          <SearchResultDetail
            value={budgetValue}
            label="€"
            highlight={false}
            type="BUDGET"
            tooltip={{
              content: <p>Total budget available for this topic.</p>,
              position: 'topRight',
            }}
          />
        </div>

        <div className="search-results--label-area">
          <ResultLabel
            label="Type of action"
            value={call.type_of_action}
            tooltip={{
              content: <TypeOfActionTooltipContent />,
              position: 'topLeft',
            }}
          />

          {call.trl_text ? (
            <ResultLabel
              label="TRL"
              value={call.trl_text}
              tooltip={{
                content: <TrlTooltipContent />,
                position: 'topLeft',
              }}
            />
          ) : null}
        </div>

        <Share
          topicMetadata={{
            index: index,
            title: call.title,
            deadline_dates: call.deadline_dates,
            budget_groups: call.budget_groups,
            call_programme: call.call_title,
          }}
        />
      </div>
    </div>
  );
};

interface IPropsFromState {
  aisearchResults: any;
  partners: any;
  saved: any;
  user: auth0.Auth0UserProfile;
  onLoadMore: (v: any) => void;
}

interface ISearchProps {
  query: string;
  statuses?: string[];
  exclude: any[];
}

export interface IDetailsList {
  sortedItems: any[];
  searchResultOffset: number;
  selectedItems: any[];
  columns: IColumn[];
  showPanel: boolean;
  selectedCallId: string;
}

class SearchResults extends React.Component<IPropsFromState, IDetailsList> {
  constructor(props: IPropsFromState) {
    super(props);

    this.state = {
      sortedItems: [],
      selectedItems: [],
      columns: [],
      showPanel: false,
      selectedCallId: '',
      searchResultOffset: 0,
    };

    this.selectCall = this.selectCall.bind(this);
    this.getSelectedItem = this.getSelectedItem.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(props: IPropsFromState) {
    this.filterItems(props.aisearchResults.results);
  }

  isSelected(call: any): boolean {
    return !!this.props.aisearchResults.selectedItems.find((obj: any) => obj.id === call.id);
  }

  isSaved(call: any): boolean {
    return !!this.props.saved.savedDocuments.find(
      (obj: any) => obj.objectId === call.topic_id && obj.scope === selectableTabs.FUNDING,
    );
  }

  selectCall(call: any): void {
    this.setState({
      selectedCallId: call.id,
      showPanel: true,
    });
    store.dispatch(aiSearchClosedTopicsRequest({ topics: { identifier: call.identifier } }));
  }

  render() {
    const { isFetching, results } = this.props.aisearchResults;
    const { showPanel, sortedItems } = this.state;

    const loadingSpinnerStyling = {
      display: 'flex',
      height: '50vh',
      justifyContent: 'center',
    };

    return (
      <div className="search-results--container">
        <div
          className="search-results--content"
          style={isFetching ? { opacity: 0.5, pointerEvents: 'none' } : {}}
        >
          {results &&
            results.length > 0 &&
            sortedItems
              .filter(this.filterCondition)
              .sort(this.compare)
              .map((result: IAISearchResult, index: number) => {

                return (
                  <CallRow
                    index={index}
                    call={result}
                    selected={this.isSelected(result)}
                    key={createId()}
                    saved={this.isSaved(result)}
                    selectCall={this.selectCall}
                  />
                );
              })}
        </div>

        {showPanel ? 
        (
          
          <SearchResult
            topic={this.getSelectedItem()}
            onDismiss={() => this.setState({ showPanel: false })}
          />
        ) : null}

        {!isFetching && results.length > 0 && (
          <ActionButton text="Load more" handleClick={this.handleLoadMore} />
        )}

        {!isFetching && results.length > 0 && isTrialUser() && (
          <p>
            To see results details,
            <a href="https://spinbase.eu/pricing/"> please consider upgrading your subscription.</a>{' '}
          </p>
        )}

        {isFetching && (
          <div style={loadingSpinnerStyling}>
            <Spinner />
          </div>
        )}
      </div>
    );
  }

  removeOpenInProjects(item: any) {
    const { selectedTab } = this.props.aisearchResults;
    const isProjects: boolean = selectedTab === selectableTabs.PROJECTS;
    return !isProjects || item.topic_status === 'Closed';
  }

  filterCondition = (item: any) => {
    const { filterConditions } = this.props.aisearchResults;

    return containsValue(item, filterConditions) && this.removeOpenInProjects(item);
  };

  compare = (a: any, b: any) => {
    const { orderCondition } = this.props.aisearchResults;

    switch (orderCondition.key) {
      case 'first_deadline':
        if (a[orderCondition.key] === 'N/A') {
          return 1;
        }

        if (b[orderCondition.key] === 'N/A') {
          return -1;
        }

        const mb = moment(b[orderCondition.key]);
        const ma = moment(a[orderCondition.key]);

        return mb.isBefore(ma) ? 1 : mb.isAfter(ma) ? -1 : 0;
      case 'similarity':
        return b[orderCondition.key] - a[orderCondition.key];
      case 'trl_text':
        const aTrlNumber = a[orderCondition.key] ? getTrlNumber(a[orderCondition.key]) : -1;
        const bTrlNumber = b[orderCondition.key] ? getTrlNumber(b[orderCondition.key]) : -1;
        return bTrlNumber - aTrlNumber;
      case 'budget_numbers':
        const aBudget = getTotalBudget(a.budget_groups as IBudgetGroup[]);
        const bBudget = getTotalBudget(b.budget_groups as IBudgetGroup[]);
        return bBudget - aBudget;
      default:
        return a[orderCondition.key] - b[orderCondition.key];
    }
  };

  public filterItems = memoize((originalList: any[]) => {
    const newList = [
      ...originalList.map((item: any) => {
        return {
          id: item.id,
          key: item.id,
          first_deadline: getNextDeadline(item.metadata.deadline_dates),
          title: item.metadata.title,
          topic_id: item.id,
          description: item.summary.preview,
          html_desc: item.summary.preview,
          similarity: (item.quality * 100).toFixed(2),
          identifier: item.metadata.identifier,
          topic_status: item.metadata.topic_status,
          call_title: item.metadata.call_title,
          call_identifier: item.metadata.identifier,
          deadline_dates: item.metadata.deadline_dates,
          budget_numbers: item.metadata.budget_numbers,
          trl_text: item.metadata.trl_text,
          type_of_action: item.metadata.type_of_action,
          call_programme_description: item.metadata.call_programme_description,
          planned_opening_date: item.metadata.planned_opening_date,
          publication_date: item.metadata.publication_date,
          budget_groups: item.metadata.budget_groups,
          topic_url: item.metadata.topic_url,
        };
      }),
    ];

    this.setState({ sortedItems: newList });
  });

  private getSelectedItem() {
    return this.props.aisearchResults.results.find((item: any) => item.id === this.state.selectedCallId);
  }

  private handleLoadMore() {
    this.setState(
      {
        searchResultOffset: this.state.searchResultOffset + 30,
      },
      () => {
        const statuses = this.props.aisearchResults.filterConditions
          .filter((filter: any) => filter.key === 'topic_status')
          .map(({ value }: any) => value);
        console.log("***",this.props.aisearchResults);
        
        this.props.onLoadMore({
          statuses,
          exclude: this.props.aisearchResults.exclude,
          query: this.props.aisearchResults.query,
          offset: this.state.searchResultOffset,
          limit: 30,
          be_offset: this.props.aisearchResults.be_offset,
        });
      },
    );
  }
}

const getNextDeadline = (dates: string[]): Moment | string => {
  const currentDate = moment();

  if (dates[0] !== 'N/A') {
    let nextDeadline = moment(dates[0]);
    for (const dateStr of dates) {
      if (dateStr !== 'N/A') {
        const date = moment(dateStr);
        if (currentDate < date) {
          nextDeadline = date;

          return nextDeadline;
        }
      }
    }
  }
  return 'N/A';
};

const mapStateToProps = ({ aisearchResults, partners, user, saved }: IState) => {  
  return { aisearchResults, partners, user, saved };
};

const mapDispatchToProps = (dispatch: any) => ({
  onLoadMore: (data: ISearchProps) => dispatch(aiSearchMoreRequest({ ...data })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);

const TypeOfActionTooltipContent = () => {
  return (
    <>
      <p>This is the “Type of action”</p>
    </>
  );
};

const TrlTooltipContent = () => {
  return (
    <>
      <p>This is the “Technology Readiness Level”. This stands for Technology Readiness Level.</p>

      <p>
        TRLs are a method of estimating the maturity of technologies, originally developed by NASA in the
        1970's. The EU prefers to use the same technique in some of its funding instruments.
      </p>

      <p>
        The projects that start from the lower levels of TRL (1-4) focus on research and development. The
        projects that are higher in the TRL scale, are usually for technology validation (TRL 4-6),
        close-to-market innovations and scaling up (TRL 6-9).
      </p>
    </>
  );
};

interface IBudgetGroup {
  budget_year_map: {
    [key: string]: string | number;
  };
}

//  _____________________________________  //
const getTotalBudget = (budgetGroups: IBudgetGroup[]): number => {
  let totalBudget = 0;
  try {
    if (budgetGroups.length) {
      const budgetYearMap = Object.entries(budgetGroups[0].budget_year_map).sort(sortByYear);

      if (budgetYearMap.length) {
        const budget = budgetYearMap[0][1];

        totalBudget += typeof budget === 'string' ? parseStringBudget(budget) : budget;
      }
    }
  } catch (e) {
    console.error(e);
  }

  return totalBudget;
};

const parseStringBudget = (budget: string): number => {
  const str = parseFloat(budget.replace(/,/g, ''));

  return isNaN(str) ? 0 : str;
};

const sortByYear = (a: any, b: any): number => {
  const currentYear = moment().get('y');

  // Moves prior years to the end of the array
  if (Number(a[0]) <= currentYear) {
    return -1;
  }

  return Number(a[0]) - Number(b[0]);
};

//  _____________________________________  //
const getBudgetFromBudgetGroups = (budgetGroups: IBudgetGroup[]): string => {
  const totalBudget = getTotalBudget(budgetGroups);

  return totalBudget === 0 ? 'N/A' : formatNumberBudget(totalBudget);
};

const formatNumberBudget = (budget: number): string => {
  if (budget >= 100_000_000) {
    return (budget / 1_000_000).toFixed(0) + ' M';
  }

  if (budget >= 1_000_000) {
    return (
      (budget / 1_000_000).toLocaleString('en', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      }) + ' M'
    );
  }

  return budget.toLocaleString('en');
};