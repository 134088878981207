import * as React from 'react';
import { connect } from 'react-redux';
import { setQuery } from '../../../app/Search/actions';
import { IState } from '../../../reducers';
import { Tooltip } from '../../Tooltip';
import { aiSetQuery } from '../../../app/AiSearch/actions';

interface ISearchFieldProps {
  aisearchResults: any;
  setQuery: (e: any) => void;
  aiSetQuery:(e:any)=>void;
}

interface ISearchFieldState {
  query: string;
}

class SearchField extends React.Component<ISearchFieldProps, ISearchFieldState> {
  state = {
    query: '',
  };

  constructor(props: ISearchFieldProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({ query: this.props.aisearchResults.query });
  }

  handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({
      query: e.target.value,
    });
  }

  handleKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      this.handleSubmit(e);
    }
  }

  handleSubmit(_e: React.KeyboardEvent<HTMLTextAreaElement>) {
    this.props.setQuery(this.state.query);
    this.props.aiSetQuery(this.state.query);
  }

  render() {
    return (
      <div className="search-field">
        <label htmlFor="search-field">
          Enter your idea description <Tooltip content={<TooltipContent />} />
        </label>

        <textarea
          id="search-field"
          className={this.props.aisearchResults.results.length > 0 ? 'shrink' : ''}
          rows={12}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          defaultValue={this.state.query}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ aisearchResults }: IState) => {
  return { aisearchResults };
};

const mapDispatchToProps = (dispatch: any) => ({
  setQuery: (query: string) => {
    dispatch(setQuery({ query }));
  },
  aiSetQuery: (query: string) => {
    dispatch(aiSetQuery({ query }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchField);

const TooltipContent = () => {
  return (
    <p>
      Enter search text for your idea looking for funding and partners. Longer description leads to better and
      more comprehensive results. Examples of good search texts are project descriptions, company profiles,
      research topic abstracts or press releases. Press “Enter” to see the results once the text is written.
    </p>
  );
};
