import * as React from 'react';

import { IState } from '../../../../../reducers';
import './SearchResult.css';

import { connect } from 'react-redux';

import { ISearchState } from '../../../../../app/Search/reducer';
import { ContentDialog } from '../../../../ContentDialog';
import { SearchResultAside } from './Aside';
import { SearchResultMain } from './Main';
import { Share } from './Share';
import { aiFetchTopicDescription } from '../../../../../app/AiSearch/actions';

interface ISearchResultProps {
  aisearchResults: ISearchState;
  topic: {
    id: any;
    metadata: {
      title: string;
      identifier: string;
      description: string;
      topic_url: string;
      call_url: string;
      call_programme: string;
      call_identifier: string;
      call_title: string;
      call_status: string;
      topic_status: string;
      call_programme_description: string;
      publication_date: string;
      planned_opening_date: string;
      deadline1: string;
      tags: string[];
      keywords: string[];
      summary_terms: string[];
      budgets_from_call: any[];
      budget_groups: any[];
    };
  };
  onDismiss: () => any;
  aiFetchTopicDescription: (v: any) => void;
}

class SearchResult extends React.Component<ISearchResultProps> {
  constructor(props: ISearchResultProps) {
    super(props);
  }

  shouldComponentUpdate(nextProps: ISearchResultProps) {
    const {
      topic: nextPropsTopic,
      aisearchResults: { topicDescription: nextPropsTopicDescription },
    } = nextProps;
    return (
      nextPropsTopic !== undefined &&
      nextPropsTopic.id !== undefined &&
      nextPropsTopicDescription[nextPropsTopic.id] !== undefined
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps: ISearchResultProps) {
    if (
      nextProps.topic &&
      this.props.topic &&
      !nextProps.aisearchResults.topicDescription[nextProps.topic.id] &&
      !nextProps.aisearchResults.isDescriptionFetching
    ) {
      this.props.aiFetchTopicDescription({
        topicId: nextProps.topic.id,
        query: nextProps.aisearchResults.query,
      });
    }
  }

  render() {
    const { topic } = this.props;

    const hideCurrentModal = () => {
      this.props.onDismiss();
      document.body.classList.remove('preventModalScrolling');
    };

    return (
      <ContentDialog
        loading={{
          title: !topic || !('metadata' in topic),
          content:
            !topic ||
            !('metadata' in topic) ||
            !(topic.id in this.props.aisearchResults.topicDescription) ||
            this.props.aisearchResults.isDescriptionFetching,
          aside: !topic || !('metadata' in topic),
        }}
        handleClose={hideCurrentModal}
        title={topic.metadata ? topic.metadata.title.replace(/<(?:.|\n)*?>/gm, '') : ''}
        content={<SearchResultMain searchResults={this.props.aisearchResults} topic={topic} />}
        aside={<SearchResultAside topic={topic} />}
        asideAfter={<Share topicMetadata={topic.metadata} />}
      />
    );
  }
}
const mapStateToProps = ({ aisearchResults }: IState) => {
  return { aisearchResults };
};

const mapDispatchToProps = (dispatch: any) => ({
  aiFetchTopicDescription: (data: any) => dispatch(aiFetchTopicDescription({ ...data })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);