import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import callApi from '../../utils/callApi';
import * as actions from './actions';
import { isTrialUser } from '../Auth/Auth';

function* handleFetch(action: any): any {
  console.log('***', action);

  const param = {
    searchCategory: 'eu_topic',
    status: action.payload.statuses ? action.payload.statuses.join(',') : 'Forthcoming,Draft,Open,Closed',
    q: action.payload.query,
    limit: action.payload.limit,
    offset: action.payload.offset,
    isTrialUser: isTrialUser(),
    be_offset: action.payload.be_offset,
  };

  try {
    const res = yield call(callApi, 'post', process.env.REACT_APP_SEARCH_API_URL || '', `/v3/search`, param);
    if (res) {
      if (res.description) {
        throw new Error(res.description);
      }
      if (res.error) {
        throw new Error(res.error);
      }
      const formattedData = {
        be_offset: res.data.be_offset,
        results: res.data.results,
      };
      switch (action.type) {
        case '@@ai-search/REQUEST': {
          yield put(actions.aiSearchResponse(formattedData));
          break;
        }
        case '@@ai-search/MORE_REQUEST': {
          yield put(actions.aiSearchMoreResponse(formattedData));
          break;
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.aiSearchError(err.message));
    } else {
      yield put(actions.aiSearchError('An unknown error occured.'));
    }
  }
}

function* handleClosedTopicsFetch(action: any): any {
  const payload = action.payload;

  try {
    const res = yield call(
      callApi,
      'post',
      process.env.REACT_APP_SEARCH_API_URL || '',
      `/v3/search/closed_topics`,
      payload,
    );

    if (res) {
      if (res.error) {
        throw new Error(res.error.code);
      }
      switch (action.type) {
        case '@@ai-search/CLOSED_TOPICS_REQUEST': {
          yield put(actions.aiSearchClosedTopicsResponse(res));
          break;
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.aiSearchError(err.message));
    } else {
      yield put(actions.aiSearchError('An unknown error occured.'));
    }
  }
}

function* handleFetchTopicDescription(action: any): any {
  const { payload } = action;
  const { topicId, query } = payload;
  const data = { query_data: query, searchCategory: 'eu_topic' };
  try {
    const res = yield call(
      callApi,
      'POST',
      process.env.REACT_APP_SEARCH_API_URL || '',
      `/v3/search/documents/${topicId}`,
      data,
    );

    if (res) {
      if (res.error) {
        throw new Error(res.error.code);
      }
      switch (action.type) {
        case actions.AISearchActionTypes.FETCH_TOPIC_DESCRIPTION_REQUEST: {
          if (typeof res.identifier !== 'undefined') {
            yield put(
              actions.aiSearchClosedTopicsRequest({
                topics: { identifier: res.identifier },
              }),
            );
          }
          yield put(actions.aiFetchTopicDescriptionSuccess(res));
          break;
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.aiSearchError(err.message));
    } else {
      yield put(actions.aiSearchError('An unknown error occured.'));
    }
  }
}

function* handleFetchTopicInterestedPartners(action: any): any {
  const { identifier, id } = action.payload;
  try {
    const res = yield call(
      callApi,
      'get',
      process.env.REACT_APP_SEARCH_API_URL || '',
      `/v3/search/topic-interested-partners/${identifier}`,
    );
    if (res) {
      if (res.error) {
        throw new Error(res.error.code);
      }
      switch (action.type) {
        case actions.AISearchActionTypes.SELECT_ITEM: {
          yield put(actions.aiFetchTopicInterestedPartnersSuccess(res, id));
          break;
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.aiSearchError(err.message));
    } else {
      yield put(actions.aiSearchError('An unknown error occured.'));
    }
  }
}

function* watchAISearchRequest() {
  yield takeEvery(actions.AISearchActionTypes.SEARCH_REQUEST, handleFetch);
}

function* watchAISearchMoreRequest() {
  yield takeEvery(actions.AISearchActionTypes.SEARCH_MORE_REQUEST, handleFetch);
}

function* watchAISearchClosedTopicsRequest() {
  yield takeEvery(actions.AISearchActionTypes.SEARCH_CLOSED_TOPICS_REQUEST, handleClosedTopicsFetch);
}

function* fetchAITopicDescription() {
  yield takeEvery(actions.AISearchActionTypes.FETCH_TOPIC_DESCRIPTION_REQUEST, handleFetchTopicDescription);
}

function* fetchAITopicInterestedPartnersRequest() {
  yield takeEvery(actions.AISearchActionTypes.SELECT_ITEM, handleFetchTopicInterestedPartners);
}

function* aiSearchSaga() {
  yield all([
    fork(watchAISearchRequest),
    fork(watchAISearchMoreRequest),
    fork(watchAISearchClosedTopicsRequest),
    fork(fetchAITopicDescription),
    fork(fetchAITopicInterestedPartnersRequest),
  ]);
}

export default aiSearchSaga;
